import { j as e } from "./jsx-runtime-B6kdoens.js";
import { useState as i } from "react";
import "./Accordion-Cet4lWC4.js";
import "./Avatar-D2CKq5wD.js";
import "./Badge-C0bj4DFU.js";
import "./Button-DhTfdjdw.js";
import "./DropdownMenu-BlzA7Hgh.js";
import "./Icon-bQEUFl7D.js";
import { I as s } from "./Input-vl7Xx2ol.js";
import "./Label-BKL8LTmX.js";
import { T as l } from "./Text-DOFuHqUo.js";
import "./Tooltip-ae2E5kx6.js";
import "./Stack-9Jnn74Nm.js";
import "./Switch-DbJ1j3KM.js";
import "./Toast-7foBnfuK.js";
import "./Toggle-DH_2vm0p.js";
import { A as o } from "./AlertDialog.component-C4dDhAuK.js";
import { S as m } from "./Separator.component-CH2uOQZ5.js";
const D = (a) => {
  const [t, r] = i("");
  return /* @__PURE__ */ e.jsx(
    o,
    {
      onOpenChange: a.onOpenChange,
      open: a.open,
      children: /* @__PURE__ */ e.jsxs(
        o.Content,
        {
          "aria-label": "Rename",
          className: "h-auto max-w-xs rounded-md z-full-screen",
          children: [
            /* @__PURE__ */ e.jsx(l, { className: "px-3 pt-3", children: "Rename" }),
            /* @__PURE__ */ e.jsx(m, {}),
            /* @__PURE__ */ e.jsx("div", { className: "px-3", children: /* @__PURE__ */ e.jsx(
              s,
              {
                autoFocus: !0,
                "aria-label": "Rename",
                name: "name",
                onChange: (n) => r(n.target.value),
                placeholder: "New name...",
                defaultValue: a.name
              }
            ) }),
            /* @__PURE__ */ e.jsx(m, {}),
            /* @__PURE__ */ e.jsxs(o.Footer, { children: [
              /* @__PURE__ */ e.jsx(
                o.Cancel,
                {
                  autoFocus: !1,
                  children: "Cancel"
                }
              ),
              /* @__PURE__ */ e.jsx(
                o.Action,
                {
                  onClick: () => a.onRename(t),
                  disabled: !t || a.name === t,
                  children: "Save"
                }
              )
            ] })
          ]
        }
      )
    }
  );
};
export {
  D as R
};
